.sv-bookmarklist-portlet ol {
   list-style: none;
   padding: 0;
   margin: 0
}

.sv-bookmarklist-portlet .sv-entry-img {
   float: left;
   width: 32px;
   margin: 3px 10px 0 0;
}

.sv-bookmarklist-portlet .sv-entry-img-container {
   width: 32px;
   float: left;
   margin: 3px 10px 0 0;
   text-align: center;
}

.sv-bookmarklist-portlet .sv-entry-img-container img {
   max-width: 100%;
}

.sv-bookmarklist-portlet .sv-bookmarked-entry .sv-message-info {
   margin-top: 5px;
}

.sv-bookmarklist-portlet .sv-bookmarked-entry {
   position:relative;
   box-sizing:border-box;
   margin:0;
   text-align: left;
   padding: 8px;
   word-wrap: break-word;
   border-top: 1px solid #eee;
   cursor: pointer;
}

.sv-bookmarklist-portlet .sv-bookmarked-entry .sv-truncate-more {
   display: none;
}

.sv-no-touch .sv-bookmarklist-portlet .sv-bookmarked-entry .sv-remove {
   position: absolute;
   top: 5px;
   right: 5px;
   display: none;
}

.sv-bookmarklist-portlet .sv-bookmarked-entry .halflings-icon {
   vertical-align: middle;
}

.sv-bookmarklist-portlet .sv-message-container {
   overflow: hidden;
}

.sv-bookmarklist-portlet a {
   text-decoration: none;
}
.sv-no-touch .sv-bookmarklist-portlet .sv-bookmarked-entry:hover .sv-remove {
   display: inherit;
}

.sv-no-touch .sv-bookmarklist-portlet .sv-bookmarked-entry:hover {
   min-height:20px;
   background-color:#F5F5F5;
   border-radius:4px;
}

.sv-touch .sv-bookmarklist-portlet .sv-bookmarked-entry .sv-remove {
   display: inherit;
   opacity: 1;
   padding: 2px
}

.sv-touch .sv-bookmarklist-portlet .sv-bookmarked-entry {
   display: inherit;
}