.sv-predefinedcontactsearch-portlet .sv-social-entry {
    border-top: 1px solid #EEEEEE;
    padding: 1em 0;
}

.sv-predefinedcontactsearch-portlet .sv-buddy-icon {
    margin-right:1em
}

.sv-predefinedcontactsearch-portlet .sv-load-more {
    width:100%
}

.sv-predefinedcontactsearch-portlet .sv-function-container {
   float:right;
   margin-left:1em;
   margin-right:1em
}

.sv-predefinedcontactsearch-portlet .sv-message-container {
   overflow:hidden
}