/**
 * Copyright (C) SiteVision AB 2002-2013, all rights reserved
 *
 * @author Karl Eklöf
 */

.sv-fileshareall-portlet .sv-list-1-columns,
.sv-fileshare-portlet .sv-list-1-columns {
   list-style:none;
   padding:0;
   margin:0
}

.sv-fileshareall-portlet .downdown-toggle,
.sv-fileshare-portlet .downdown-toggle {
   text-decoration:none
}

.sv-fileshareall-portlet .dropdown-menu li,
.sv-fileshare-portlet .dropdown-menu li {
   position:relative
}

.sv-fileshareall-portlet form.sv-fn-upload,
.sv-fileshare-portlet form.sv-fn-upload {
   float:left;
   margin:2px 0 0 0
}

.sv-fileshareall-portlet .sv-download-file,
.sv-fileshare-portlet .sv-download-file {
   position:absolute;
   top:6px;
   right:6px;
   display:none;
   background-color:#F5F5F5;
   padding:3px 3px 3px 8px
}

.sv-fileshareall-portlet .sv-download-file:hover,
.sv-fileshare-portlet .sv-download-file:hover {
   background-color:white;
   text-decoration:underline
}

.sv-no-touch .sv-fileshareall-portlet .sv-file-listitem:hover .sv-download-file,
.sv-no-touch .sv-fileshare-portlet .sv-file-listitem:hover .sv-download-file {
   display:inherit
}

.sv-fileshareall-portlet .sv-download-file a,
.sv-fileshare-portlet .sv-download-file a {
   text-decoration:none
   /*color: #999*/
}

.sv-fileshareall-portlet .sv-download-file i.halflings-icon,
.sv-fileshare-portlet .sv-download-file i.halflings-icon {
   margin-right:1px
}

.sv-fileshareall-portlet .sv-upload-progress,
.sv-fileshare-portlet .sv-upload-progress {
   margin-left:5px
}

.sv-fileshareall-portlet .sv-list-1-columns li.sv-file-listitem,
.sv-fileshare-portlet .sv-list-1-columns li.sv-file-listitem {
   float:left;
   width:100%
}

.sv-fileshareall-portlet li.sv-file-listitem,
.sv-fileshare-portlet li.sv-file-listitem {
   box-sizing:border-box;
   padding:0;
   margin:0
}

/* default grid */
.sv-fileshareall-portlet li.sv-file-listitem,
.sv-fileshare-portlet li.sv-file-listitem {
   text-align:center;
   position:relative;
   padding:8px;
   word-wrap:break-word
}

/* list  2 - columns */
.sv-fileshareall-portlet .sv-list-1-columns li.sv-file-listitem,
.sv-fileshare-portlet .sv-list-1-columns li.sv-file-listitem {
   text-align:left;
   padding:8px;
   word-wrap:break-word
}

.sv-fileshareall-portlet .sv-list-1-columns .sv-file-name,
.sv-fileshare-portlet .sv-list-1-columns .sv-file-name {
   margin:0 0 4px 0;
   display:block
}

.sv-fileshareall-portlet .sv-list-1-columns .sv-file-img,
.sv-fileshare-portlet .sv-list-1-columns .sv-file-img {
   float:left;
   width:16px;
   /*height:48px;*/
   margin:0 .5em 0 0
}

.sv-fileshareall-portlet .sv-controls,
.sv-fileshare-portlet .sv-controls {
   width:100%;
   padding:5px 8px
}

.sv-no-touch .sv-fileshareall-portlet .sv-file-listitem:hover,
.sv-no-touch .sv-fileshare-portlet .sv-file-listitem:hover {
   min-height:20px;
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-fileshareall-portlet .sv-file-listitem,
.sv-fileshare-portlet .sv-file-listitem {
   position:relative;
   cursor:pointer
}

.sv-fileshareall-portlet .sv-comment-menu-wrapper {
   display:none
}

.sv-fileshareall-portlet .sv-comment-menu-wrapper.sv-show-menu {
   display:inherit
}

.sv-fileshare-portlet .sv-comment-menu,
.sv-fileshareall-portlet .sv-comment-menu {
   position: absolute;
   top:0;
   right:5px;
   text-decoration:none;
   display:none
}

.sv-no-touch .sv-fileshareall-portlet .sv-comment-menu .chevron-down {
   opacity:.6
}

.sv-no-touch .sv-fileshareall-portlet .sv-file-comment:hover .sv-comment-menu {
   display:inherit
}

.sv-fileshare-portlet .sv-filter-field {
   margin-top:2px
}

.sv-fileshareall-portlet .sv-filter-label,
.sv-fileshare-portlet .sv-filter-label {
   margin:5px 1em 0 0
}

.sv-fileshareall-portlet input[type="file"],
.sv-fileshare-portlet input[type="file"] {
   position:absolute;
   top:0;
   left:0;
   margin:0;
   opacity:0;
   filter:alpha(opacity=0);
   /*transform:translate(-300px, 0) scale(4);*/
   font-size:26px !important;
   direction:ltr;
   cursor:pointer;
   height:30px;
   line-height:30px;
   width:100% !important
}

.sv-fileshare-portlet .sv-show-all {
   margin:3px 0 0 5px
}

.sv-fileshareall-portlet .dropdown,
.sv-fileshare-portlet .dropdown {
   z-index:1000
}

.sv-fileshare-portlet .sv-fileinput-field.sv-change-btn {
   overflow:hidden
}

.sv-fileshare-portlet .sv-file-info {
   overflow:hidden
}

.sv-fileshare-portlet .sv-button-container {
   height:32px;
   width:32px;
   position:relative;
   display:inline-block;
   overflow:hidden
}

.sv-fileshare-portlet button.sv-add-file.sv-change-btn {
   position:relative;
   padding:5px 6px 5px 8px;
   border-radius: 3px !important
}