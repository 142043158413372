.sv-contactsearchall-portlet .sv-social-entry {
    border-top: 1px solid #EEEEEE;
    padding: 1em 0
}

.sv-contactsearchall-portlet .sv-buddy-icon {
    margin-right:1em
}

.sv-contactsearchall-portlet .sv-load-more {
    width:100%
}

.sv-contactsearchall-portlet .sv-function-container {
   float:right;
   margin-left:1em;
   margin-right:1em
}

.sv-contactsearchall-portlet .sv-select-all-container {
   float:right;
   margin-top:1em
}

.sv-contactsearchall-portlet .sv-add-members-container {
   float:right;
   margin-bottom:1em
}

.sv-contactsearchall-portlet .sv-message-container {
   overflow:hidden
}

.sv-contactsearchall-portlet .sv-admin-container {
   margin-top:5px;
   margin-left:5px
}

.sv-contactsearchall-portlet .sv-no-result-container {
   margin-top:5px
}

.sv-contactsearchall-portlet .sv-admin-container label {
   display:inline
}

.sv-contactsearchall-portlet .sv-admin-container input {
   vertical-align:text-bottom
}